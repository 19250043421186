<template>
  <v-dialog
    width="500"
    :value="dialog"
    @input="close"
  >
    <v-container
      fluid
      :style="style"
    >
      <v-row
        class="text-center secondary--text"
        style="background: #55855D"
      >
        <v-col>
          <v-container>
            <v-icon
              color="secondary"
              large
            >
              mdi-email-outline
            </v-icon>
            <h3>{{ $t('email.title') }}</h3>
          </v-container>
        </v-col>
      </v-row>
      <!-- EMAIL -->
      <v-row class="text-center pt-6">
        <v-col>
          <v-form
            v-model="isFormValid"
          >
          <v-text-field
            id="email"
            type="email"
            autocomplete="email"
            v-model="email"
            :rules="rules.email"
            :label="$t('email.label')"
            outlined
            required
          ></v-text-field>
        </v-form>
        </v-col>
      </v-row>
      <SubmissionArea
        :disabled="!isFormValid"
        :loading="submitting"
        @close="close"
        @submit="submit"
      />
    </v-container>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import lazyLoad from '@/utils/lazyLoad';
import { email } from '@/utils/validators';

export default {
  name: 'Email',
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SubmissionArea: lazyLoad('components/Dialogs/SubmissionArea'),
  },
  data: () => ({
    email: '',
    isFormValid: true,
    submitting: false,
    rules: {
      email,
    },
  }),
  mounted() {
    this.reset();
  },
  computed: {
    ...mapGetters('testMicro', [
      'id',
      'result',
    ]),
    style() {
      return 'background-color: rgb(255, 255, 255)';
    },
    template() {
      return {
        subject: this.$t(`email.template.subject.${this.id}`),
        cta: this.$t('email.template.cta'),
        greeting: this.$t('email.template.greeting'),
        message: this.$t('email.template.message'),
        htmlResult: this.result,
        signatureGreeting: this.$t('email.template.signatureGreeting'),
        signature: this.$t('email.template.signature'),
        ps: this.$t('email.template.ps'),
        contact: this.$t('email.template.contact'),
        footer: this.$t('email.template.footer'),
        toEmail: this.email,
      };
    },
  },
  methods: {
    ...mapActions('email', [
      'reset',
      'setEmail',
      'postEmailData',
      'sendUserEmail',
    ]),
    close() {
      this.$emit('close');
    },
    async submit() {
      this.submitting = true;
      try {
        await this.setEmail(this.email);
        this.$notify.info(this.$t('email.notification.sending'));
        await this.sendUserEmail(this.template);
        await this.postEmailData();
        this.$notify.success(this.$t('email.notification.sent'));
      } catch (error) {
        this.$notify.error(error);
        throw new Error(error);
      } finally {
        this.submitting = false;
        await new Promise((resolve) => setTimeout(resolve, 2000));
        this.close();
      }
    },
  },
};
</script>
