import i18n from '@/plugins/i18n';

const email = [
  (v) => !!v || i18n.t('email.validators.required'),
  (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || i18n.t('email.validators.valid'),
];

export {
  email,
};
